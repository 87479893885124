define(['app', 'siteObj'], function(app, siteObj) {

  var _myAccountCheckForLiveChat = {

    _elementSelectors: {
      myAccountWrapper: '.js-myAccount-wrapper, .helpCentre_wrapper,'
        + ' .responsiveOrderDetails',
      LPMcontainer: '.LPMcontainer',
      liveChatAvailability: '[data-js-live-chat-availability=true]',
      lpPanel: '.lp-panel',
      lpDesktop: '.lp_desktop',
      lpMobile: '.lp_mobile',
      lpMainArea: '.lp_main_area',
      lpSurveyArea: '.lp_survey_area',
      lpPagingWrapper: '.lp_paging_wrapper',
      lpLocationCenter: '.lp_location_center',
      lpcWindow: '.lpc_window',
      lpcDesktop: '.lpc_desktop',
      lpcMobile: '.lpc_mobile'
    },
    _attrib: {
      dataCSMask: 'data-cs-mask'
    },

    _elementClasses: {
      liveChatButtonMask: 'csCard-liveChatButtonMask',
      liveChatAvailableCSS: 'csCard_notificationLabel-available',
      showComponentClassName: 'liveChat-show'
    },

    _text: {
      available: siteObj.props.account.liveChatAvailable,
      unavailable: siteObj.props.account.liveChatUnavailable,
    },

    _checkForLiveChat: true,

    _checkForLiveChatInterval: 2000,

    _init: function() {
      _myAccountCheckForLiveChat._bindEvents();
      _myAccountCheckForLiveChat._isLiveChatAvailable();
    },

    _bindEvents: function() {
      var myAccountPageWrapper = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.myAccountWrapper);
      app.on('click', _myAccountCheckForLiveChat._clickEvent, myAccountPageWrapper);
      myAccountPageWrapper && myAccountPageWrapper.addEventListener('keypress', e => e.key === 'Enter' && _myAccountCheckForLiveChat._clickEvent(e));
    },

    _clickEvent: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (!target) {
        return;
      }

      if (app.element.hasClass(_myAccountCheckForLiveChat._elementClasses.liveChatButtonMask, target)) {
        _myAccountCheckForLiveChat._openLiveChat();
      }
    },

    _isLiveChatAvailable: function() {
      if (_myAccountCheckForLiveChat._checkForLiveChat) {
        setTimeout(function() {
          if (document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpDesktop)) {
            _myAccountCheckForLiveChat._setCSMask();
          }
          if (document.querySelector(_myAccountCheckForLiveChat._elementSelectors.LPMcontainer)) {
            _myAccountCheckForLiveChat._showLiveChatAvailable();
            _myAccountCheckForLiveChat._checkForLiveChat = false;
          } else {
            _myAccountCheckForLiveChat._isLiveChatAvailable();
          }
        }, _myAccountCheckForLiveChat._checkForLiveChatInterval);
      }
    },

    _showLiveChatAvailable: function() {
      var liveChatAvailabilityElement = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.liveChatAvailability);

      if (liveChatAvailabilityElement) {
        liveChatAvailabilityElement.innerHTML = _myAccountCheckForLiveChat._text.available;
        app.element.addClass(_myAccountCheckForLiveChat._elementClasses.liveChatAvailableCSS, liveChatAvailabilityElement);
      }
    },

    _openLiveChat: function() {
      var lpPanel = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpPanel);
      var liveChatAvailabilityElement = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.liveChatAvailability);
      var chatbotButton = document.querySelector('.LPMcontainer')

      // if all are available, then we can click the chatbot trigger button
      if (lpPanel && liveChatAvailabilityElement && chatbotButton) {
        document.querySelector('.LPMcontainer').click()
      }
    },

    _setCSMask: function() {
      setTimeout(function() {
        var lpDesktop = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpDesktop);
        var lpMobile = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpMobile);
        var lpMainArea = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpMainArea);
        var lpSurveyArea = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpSurveyArea);
        var lpPagingWrapper = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpPagingWrapper);
        var lpLocationCenter = document.querySelector(_myAccountCheckForLiveChat._elementSelectors.lpLocationCenter);

        var lpcWindow = document.querySelectorAll(_myAccountCheckForLiveChat._elementSelectors.lpcWindow);
        var lpcDesktop = document.querySelectorAll(_myAccountCheckForLiveChat._elementSelectors.lpcDesktop);
        var lpcMobile = document.querySelectorAll(_myAccountCheckForLiveChat._elementSelectors.lpcMobile);

        if (lpDesktop == null) {
          lpMobile.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');
          Array.from(lpcMobile).map(el => el.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, ''));
        } else {
          lpDesktop.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');
          Array.from(lpcDesktop).map(el => el.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, ''));
        }

        lpMainArea.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');
        lpSurveyArea.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');
        lpPagingWrapper.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');
        lpLocationCenter.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, '');

        Array.from(lpcWindow).map(el => el.setAttribute(_myAccountCheckForLiveChat._attrib.dataCSMask, ''));

      }, (_myAccountCheckForLiveChat._checkForLiveChatInterval));
    }
  };

  // Execute module constructor
  _myAccountCheckForLiveChat._init();


  // Unit testing access
  return _myAccountCheckForLiveChat;
});
